@font-face {
    font-family: 'Karla';
    src: local('Karla'),
         url('./assets/fonts/Karla/Karla-VariableFont_wght.ttf') format('truetype'),
         url('./assets/fonts/Karla/Karla-Italic-VariableFont_wght.ttf') format('truetype');
}
@font-face {
    font-family: 'Prata';
    src: local('Prata'),
         url('./assets/fonts/Prata/Prata-Regular.ttf') format('truetype');
}

.footer-logo {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.footer-logo svg {
    height: 150px;
    width: 150px;
}
