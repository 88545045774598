.slick-track {
    display: flex;
}

.post-slider {
    padding: 13px;
}
.slider-img {
    height: 415px;
    object-fit: cover;
}

.slick-arrow.slick-prev {
    position: absolute;
    width: 40px;
    height: 40px;
    color: transparent;
    background-color: #3d1a0f;
    z-index: 1;
    border-radius: 100%;
    top: 217px;
    left: 5px;
    transform: translateY(-50%);
}

.slick-arrow.slick-prev::after {
    content: " ";
    color: white;
    position: absolute;
    top: 8px;
    left: 10px;
    width: 12px;
    height: 20px;
    background-image: url(./arrowLeft.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.slick-arrow.slick-next {
    position: absolute;
    width: 40px;
    height: 40px;
    color: transparent;
    background-color: #3d1a0f;
    z-index: 1;
    border-radius: 100%;
    top: 217px;
    right: 5px;
    transform: translateY(-50%);
}

.slick-arrow.slick-next::after {
    content: " ";
    color: white;
    position: absolute;
    top: 8px;
    right: 10px;
    width: 12px;
    height: 20px;
    rotate: 180deg;
    background-image: url(./arrowLeft.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}