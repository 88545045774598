.contact-info-text {
    text-align: right;
    padding-right: 30px;
    margin-right: 20px 20px 20px 0;
    margin: 20px;
    color: #3d1a0f;
    border-right: 1px solid #3d1a0f;
}

.contact-info-text p {
    color: #3d1a0f;
    margin-bottom: 0;
}

.contact-section svg {
    /* fill: red; */
    fill: #3d1a0f;
}

.ctf {
    padding: 40px;
}
input:disabled,
button:disabled,
button[disabled]{
    cursor: not-allowed;
    border: 1px solid #999999;
    color: #666666;
}
