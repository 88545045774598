body {
  background: #fff;
  font-family: "Karla", arial, sans-serif;
  font-weight: 200;
  font-size: 16px;
  line-height: 1.8;
  color: #6c757d;
  position: relative; }

::-moz-selection {
  color: #fff;
  background: #ffba5a; }

::selection {
  color: #fff;
  background: #ffba5a; }

a {
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
  text-decoration: none; }
  a:hover {
    text-decoration: none; }

h1, h2, h3, h4, h5 {
  color: #000;
  font-family: "Prata", times, serif; }

.font-family-serif {
  font-family: "Prata", times, serif; }

.font-family-sans-serif {
  font-family: "Prata", arial, sans-serif; }

.bg-light {
  background: #f2f4fb !important; }

.container-fluid {
  max-width: 1600px; }

.btn {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 50px; }
  .btn.btn-outline-white {
    border: 2px solid #fff; }
    .btn.btn-outline-white:hover {
      border: 2px solid transparent;
      background: #000;
      color: #fff; }
  .btn.btn-outline-white-primary {
    border: 2px solid #fff; }
    .btn.btn-outline-white-primary:hover {
      border: 2px solid transparent;
      background: #ffba5a;
      color: #fff; }
  .btn.uppercase {
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: .2em; }

.btn, .form-control {
  outline: none;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }
  .btn:focus, .btn:active, .form-control:focus, .form-control:active {
    outline: none; }

.form-control {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  height: 50px;
  border-width: 2px; }
  .form-control:active, .form-control:focus {
    border-color: #ffba5a; }

textarea.form-control {
  height: inherit; }

.site-header {
  position: fixed;
  top: 0;
  width: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
  z-index: 20;
  -webkit-transition: .3s all ease-in-out;
  -o-transition: .3s all ease-in-out;
  transition: .3s all ease-in-out;
}
.site-header.scrolled {
  padding: 10px 0;
  background: #000;
  -webkit-box-shadow: 0 5px 20px -5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 20px -5px rgba(0, 0, 0, 0.1);
}
.site-header.scrolled .site-menu-toggle {
  top: 10px;
  position: relative;
}
.site-header.scrolled .site-menu-toggle span {
  background: #fff;
}
.site-header.scrolled div.open span {
  background: #000;
}
.site-header.scrolled .site-logo {
  position: relative;
  z-index: 100;
}
.site-header.scrolled .site-logo a {
  color: #fff; 
}
.site-header.scrolled .site-logo a svg {
  height: 60px;
  fill: #D8A078;
}

.menu-open {
  position: relative;
}
.menu-open .site-header {
  position: fixed;
}
.menu-open .site-logo {
  position: relative;
  z-index: 100;
}
.menu-open .site-logo a {
  color: #000;
}
.menu-open .site-logo a svg {
  fill: #000;
}
.menu-open .site-navbar {
  border: 10px solid #f8f9fa;
}

.site-logo {
  padding-left: 40px;
}
.site-logo a svg {
  height: 80px;
  fill: #fff;
}

.site-logo a {
  font-size: 24px;
  color: #fff;
  font-weight: normal;
  line-height: 1;
  font-family: "Karla", times, serif;
}

@media (max-width: 991.98px) {
  .menu-desktop a {
    font-size: 27px;
  }
  .site-logo a svg {
    height: 40px;
  }
}

.site-navbar {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  border: 20px solid transparent;
  background: #fff;
  min-height: 300px;
  overflow-y: scroll; }
  .site-navbar nav {
    text-align: left; }
    .site-navbar nav .menu {
      font-family: "Karla", times, serif; }
      .site-navbar nav .menu li a {
        color: #000;
        font-size: 40px;
        padding: 5px 10px;
        position: relative; }
        .site-navbar nav .menu li a:hover:before {
          width: 100%; }
      .site-navbar nav .menu li.active a {
        color: #ffba5a; }
        .site-navbar nav .menu li.active a:before {
          width: 100%; }
  .site-navbar .extra-info a {
    color: #000; }
  .site-navbar .extra-info ul li a {
    color: #000; }
  .site-navbar .extra-info h3 {
    font-family: "Karla", arial, sans-serif;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: .2em;
    color: #adb5bd;
    margin-bottom: 30px; }
  .site-navbar .extra-info p {
    color: #212529; }

.full-height {
  height: 90vh;
  min-height: 500px; }

.site-hero {
  background-size: cover;
  height: 90vh;
  min-height: 700px;
  width: 100%;
  position: relative; }
  .site-hero .heading {
    font-family: "Karla", times, serif;
    font-weight: bold; }
  .site-hero .scroll-down {
    position: absolute;
    bottom: 20px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    color: #fff; }
  .site-hero.overlay:before {
    background-color: transparent; /* For browsers that do not 796159 gradients */
    background-image: linear-gradient(to right, transparent , #775e56);
    /* background: rgba(0, 0, 0, 0.45); */
    content: "";
    position: absolute;
    height: 90vh;
    min-height: 700px;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0; }
  .site-hero.inner-page {
    height: 50vh;
    min-height: 700px; }
    .site-hero.inner-page.overlay:before {
      height: 50vh;
      min-height: 700px; }

.site-hero-inner {
  height: 90vh;
  min-height: 700px; }
  .site-hero-inner .heading {
    font-size: 80px;
    font-family: "Prata", times, serif;
    color: #fff;
    line-height: 1.2;
    font-weight: bold; }
    @media (max-width: 991.98px) {
      .site-hero-inner .heading {
        font-size: 40px; }
    }
  .site-hero-inner .sub-heading {
    font-size: 30px;
    font-weight: 200;
    color: #fff;
    line-height: 1.5; }
    @media (max-width: 991.98px) {
      .site-hero-inner .sub-heading {
        font-size: 18px; } }

.inner-page .site-hero-inner {
  height: 50vh;
  min-height: 700px; }

.page-inside .site-hero-inner, .page-inside {
  height: 70vh;
  min-height: 500px; }

.page-inside.overlay:before {
  height: 70vh;
  min-height: 500px; }

.menu-open .site-menu-toggle span {
  background: #000; }

.site-menu-toggle {
  top: 10px;
  float: right;
  width: 40px;
  height: 45px;
  position: relative;
  margin: 0px auto;
  z-index: 200;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}
  .site-menu-toggle span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #fff;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out; }
  .site-menu-toggle span:nth-child(1) {
    top: 0px; }
  .site-menu-toggle span:nth-child(2) {
    top: 10px; }
  .site-menu-toggle span:nth-child(3) {
    top: 20px; }
  .site-menu-toggle.open span:nth-child(1) {
    top: 13px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg); }
  .site-menu-toggle.open span:nth-child(2) {
    opacity: 0;
    left: -60px; }
  .site-menu-toggle.open span:nth-child(3) {
    top: 13px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    transform: rotate(-135deg); }

.section {
  padding: 7em 0; }
  @media (max-width: 991.98px) {
    .section {
      padding: 3em 0; } }

@media (max-width: 991.98px) {
  .lead {
    font-size: 16px; } }

.visit-section .heading {
  font-size: 15px;
  text-transform: uppercase;
  font-family: "Prata", arial, sans-serif;
  color: #b3b3b3;
  letter-spacing: .2em;
  margin-bottom: 30px; }

.visit-section .visit a {
  color: #000; }
  .visit-section .visit a:hover {
    color: #ffba5a; }

.visit-section .visit img {
  -webkit-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 15px; }

.visit-section .visit h3 {
  font-size: 20px;
  margin-bottom: 0; }

.visit-section .reviews-star span {
  font-size: 18px;
  color: #ffba5a; }

.visit-section .reviews-count {
  color: #adb5bd;
  font-style: italic; }

.heading-serif, .heading, .testimonial-section .heading, .slider-section .heading, .blog-post-entry .heading {
  font-size: 60px;
  font-family: "Prata", times, serif;
  font-weight: 700; }
  @media (max-width: 991.98px) {
    .heading-serif, .heading, .testimonial-section .heading, .slider-section .heading, .blog-post-entry .heading {
      font-size: 40px; } }

.bg-pattern {
  background: #e9ecef url(../images/round.png); 
}

.slider-section {
  position: relative;
}

.blog-post-entry {
  position: relative;
}

.half .image, .half .text {
  width: 50%; }
  @media (max-width: 991.98px) {
    .half .image, .half .text {
      width: 100%; } }

.half .image {
  background-size: cover;
  background-position: center center; }
  @media (max-width: 991.98px) {
    .half .image {
      height: 300px; } }

.half .text {
  padding: 100px 7%; }
  @media (max-width: 991.98px) {
    .half .text {
      padding-top: 50px;
      padding-bottom: 50px; } }
  .half .text h2 {
    font-size: 70px; }
    @media (max-width: 991.98px) {
      .half .text h2 {
        font-size: 40px; } }

.testimonial blockquote {
  padding: 0; }

.testimonial .author-image img {
  width: 70px; }

.post .media-custom {
  background: #fff;
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
  -webkit-box-shadow: 0 2px 5px -2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 5px -2px rgba(0, 0, 0, 0.1); }
  .post .media-custom:hover, .post .media-custom:focus {
    -webkit-box-shadow: 0 10px 30px -10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 10px 30px -10px rgba(0, 0, 0, 0.1); }
  .post .media-custom a {
    color: #000; }
    .post .media-custom a:hover {
      color: #ffba5a; }
  .post .media-custom .media-body{
    padding: 10px 30px;
  }
  .post .media-custom h2 {
    font-family: "Karla", arial, sans-serif;
    color: #000;
    font-size: 18px;
  }

.media-custom p {
  font-family: "Karla", arial, sans-serif;
}
.media-custom .meta-post {
  font-family: "Karla", arial, sans-serif;
  color: #000;
  letter-spacing: .2em;
  font-size: 14px;
}

.owl-carousel .owl-item {
  opacity: .4; }
  .owl-carousel .owl-item.active {
    opacity: 1; }

.owl-carousel .owl-nav {
  position: absolute;
  top: 50%;
  width: 100%; }
  .owl-carousel .owl-nav .owl-prev,
  .owl-carousel .owl-nav .owl-next {
    position: absolute;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    margin-top: -10px; }
    .owl-carousel .owl-nav .owl-prev:hover, .owl-carousel .owl-nav .owl-prev:focus, .owl-carousel .owl-nav .owl-prev:active,
    .owl-carousel .owl-nav .owl-next:hover,
    .owl-carousel .owl-nav .owl-next:focus,
    .owl-carousel .owl-nav .owl-next:active {
      outline: none; }
    .owl-carousel .owl-nav .owl-prev span:before,
    .owl-carousel .owl-nav .owl-next span:before {
      font-size: 40px; }
  .owl-carousel .owl-nav .owl-prev {
    left: 30px !important; }
  .owl-carousel .owl-nav .owl-next {
    right: 30px !important; }

.owl-carousel .owl-dots {
  text-align: center; }
  .owl-carousel .owl-dots .owl-dot {
    border-width: 2px !important;
    width: 10px;
    height: 10px;
    margin: 5px;
    border-radius: 50%; }

.owl-carousel.home-slider {
  z-index: 1;
  position: relative; }
  .owl-carousel.home-slider .owl-nav {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease; }
    .owl-carousel.home-slider .owl-nav button {
      color: #fff; }
  .owl-carousel.home-slider:focus .owl-nav, .owl-carousel.home-slider:hover .owl-nav {
    opacity: 1;
    visibility: visible; }
  .owl-carousel.home-slider .slider-item {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: calc(100vh - 117px);
    min-height: 700px;
    position: relative; }
    .owl-carousel.home-slider .slider-item .slider-text {
      color: #fff;
      height: calc(100vh - 117px);
      min-height: 700px; }
      .owl-carousel.home-slider .slider-item .slider-text h1 {
        font-size: 40px;
        color: #fff;
        line-height: 1.2;
        font-weight: 800 !important;
        text-transform: uppercase; }
        @media (max-width: 991.98px) {
          .owl-carousel.home-slider .slider-item .slider-text h1 {
            font-size: 40px; } }
      .owl-carousel.home-slider .slider-item .slider-text p {
        font-size: 20px;
        line-height: 1.5;
        font-weight: 300;
        color: white; }
    .owl-carousel.home-slider .slider-item.dark .child-name {
      color: #000; }
    .owl-carousel.home-slider .slider-item.dark h1 {
      color: #000; }
    .owl-carousel.home-slider .slider-item.dark p {
      color: #000; }
  .owl-carousel.home-slider .owl-dots {
    position: absolute;
    bottom: 100px;
    width: 100%; }
    .owl-carousel.home-slider .owl-dots .owl-dot {
      width: 10px;
      height: 10px;
      margin: 5px;
      border-radius: 50%;
      border: 2px solid transparent;
      outline: none !important;
      position: relative;
      -webkit-transition: .3s all ease;
      -o-transition: .3s all ease;
      transition: .3s all ease;
      background: #fff; }
      .owl-carousel.home-slider .owl-dots .owl-dot.active {
        border: 2px solid white;
        background: none; }

.owl-carousel.major-caousel .owl-stage-outer {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.owl-carousel.major-caousel .owl-stage-outer {
  padding-top: 30px;
  padding-bottom: 30px; }

.owl-carousel.major-caousel .slider-item {
  height: inherit;
  min-height: inherit; }
  .owl-carousel.major-caousel .slider-item img {
    margin-bottom: 0; }

.owl-carousel.major-caousel .owl-nav {
  opacity: 1;
  visibility: visible; }
  .owl-carousel.major-caousel .owl-nav .owl-prev, .owl-carousel.major-caousel .owl-nav .owl-next {
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
    color: #495057; }
    .owl-carousel.major-caousel .owl-nav .owl-prev:hover, .owl-carousel.major-caousel .owl-nav .owl-prev:focus, .owl-carousel.major-caousel .owl-nav .owl-next:hover, .owl-carousel.major-caousel .owl-nav .owl-next:focus {
      color: #6c757d;
      outline: none; }
    .owl-carousel.major-caousel .owl-nav .owl-prev.disabled, .owl-carousel.major-caousel .owl-nav .owl-next.disabled {
      color: #dee2e6; }
  .owl-carousel.major-caousel .owl-nav .owl-prev {
    left: -60px !important; }
  .owl-carousel.major-caousel .owl-nav .owl-next {
    right: -60px !important; }

.owl-carousel.major-caousel .owl-dots {
  bottom: 50px !important; }
  @media (max-width: 991.98px) {
    .owl-carousel.major-caousel .owl-dots {
      bottom: 10px !important; } }

.owl-carousel.js-carousel-2 .slider-item {
  padding: 0 20px;
  text-align: center; }

.owl-carousel.js-carousel-2 .owl-nav {
  position: absolute;
  top: 50%;
  width: 100%;
  z-index: 299; }
  .owl-carousel.js-carousel-2 .owl-nav .owl-prev,
  .owl-carousel.js-carousel-2 .owl-nav .owl-next {
    position: absolute;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    margin-top: -10px; }
    .owl-carousel.js-carousel-2 .owl-nav .owl-prev:hover, .owl-carousel.js-carousel-2 .owl-nav .owl-prev:focus, .owl-carousel.js-carousel-2 .owl-nav .owl-prev:active,
    .owl-carousel.js-carousel-2 .owl-nav .owl-next:hover,
    .owl-carousel.js-carousel-2 .owl-nav .owl-next:focus,
    .owl-carousel.js-carousel-2 .owl-nav .owl-next:active {
      outline: none; }
    .owl-carousel.js-carousel-2 .owl-nav .owl-prev span:before,
    .owl-carousel.js-carousel-2 .owl-nav .owl-next span:before {
      font-size: 40px; }
  .owl-carousel.js-carousel-2 .owl-nav .owl-prev {
    left: -30px !important; }
  .owl-carousel.js-carousel-2 .owl-nav .owl-next {
    right: -30px !important; }

.owl-carousel.js-carousel-2 .owl-dots .owl-dot {
  display: inline-block;
  border: none; }
  .owl-carousel.js-carousel-2 .owl-dots .owl-dot > span {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #e9ecef;
    display: inline-block; }
  .owl-carousel.js-carousel-2 .owl-dots .owl-dot.active > span {
    background: #ffba5a; }
  .owl-carousel.js-carousel-2 .owl-dots .owl-dot:active, .owl-carousel.js-carousel-2 .owl-dots .owl-dot:focus, .owl-carousel.js-carousel-2 .owl-dots .owl-dot:hover {
    outline: none; }

.owl-custom-nav {
  float: right;
  position: relative;
  z-index: 10; }
  .owl-custom-nav .owl-custom-prev,
  .owl-custom-nav .owl-custom-next {
    padding: 10px;
    font-size: 30px;
    background: #ccc;
    line-height: 0;
    width: 60px;
    text-align: center;
    display: inline-block; }

.footer-section {
  background: #1a1a1a;
  color: #fff;
}
.footer-section .footer-title {
  margin-top: -15px;
 font-size: 26px;
}
.footer-section .footer-sub-title {
 font-size: 12px;
}
.footer-section svg,
.footer-section p,
.footer-section a {
  color: #fff;
  font-family: "Prata", times, serif;
  transition: all .3s ease-in-out;
}
.footer-section a:hover {
  color: rgba(255, 255, 255, 0.5);
}
.footer-section .footer-logo:hover svg {
  fill: rgba(255, 255, 255, 0.5);
  stroke: rgba(255, 255, 255, 0.5);
}

  .footer-section .bordertop {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding-top: 20px; }
  .footer-section .contact-info span.d-block {
    font-style: italic;
    color: #fff; }
  .footer-section .social a {
    font-size: 18px;
    padding: 10px; }
  .footer-section .link li {
    margin-bottom: 10px; }

.footer-newsletter .form-group {
  position: relative; }

.footer-newsletter .form-control {
  background: none;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 0;
  color: #fff; }
  .footer-newsletter .form-control::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: rgba(255, 255, 255, 0.2);
    font-style: italic; }
  .footer-newsletter .form-control::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba(255, 255, 255, 0.2);
    font-style: italic; }
  .footer-newsletter .form-control:-ms-input-placeholder {
    /* IE 10+ */
    color: rgba(255, 255, 255, 0.2);
    font-style: italic; }
  .footer-newsletter .form-control:-moz-placeholder {
    /* Firefox 18- */
    color: rgba(255, 255, 255, 0.2);
    font-style: italic; }
  .footer-newsletter .form-control:active, .footer-newsletter .form-control:focus {
    border-bottom: 1px solid white; }

.footer-newsletter button[type="submit"] {
  background: none;
  color: #fff;
  position: absolute;
  top: 0;
  right: 0; }

.side-box, .sidebar-search {
  padding: 30px;
  background: #fff;
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 30px; }
  .side-box .heading, .sidebar-search .heading {
    font-size: 18px;
    margin-bottom: 30px;
    font-family: "Prata", arial, sans-serif; }

.post-list li {
  margin-bottom: 20px; }
  .post-list li a > div {
    margin-top: -10px; }
  .post-list li a .meta {
    font-size: 13px;
    color: #adb5bd; }
  .post-list li a .image {
    width: 150px; }
  .post-list li a h3 {
    font-size: 16px; }
  .post-list li:last-child {
    margin-bottom: 0; }

.sidebar-search .form-group {
  position: relative;
  margin-bottom: 0; }

.sidebar-search .icon-search {
  position: absolute;
  top: 50%;
  left: 15px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); }

.sidebar-search .search-input {
  border-color: #dee2e6;
  padding-left: 40px;
  border-radius: 0px; }
  .sidebar-search .search-input:focus, .sidebar-search .search-input:active {
    border-color: #343a40; }

.contact-section .contact-info p {
  font-family: "Prata", times, serif;
  font-size: 30px;
  margin-bottom: 30px; }
  .contact-section .contact-info p .d-block {
    font-size: 14px;
    letter-spacing: .2em;
    font-weight: bold;
    font-family: "Prata", arial, sans-serif;
    text-transform: uppercase; }

.post-categories li {
  display: block; }
  .post-categories li a {
    display: block;
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e9ecef; }
    .post-categories li a .count {
      position: absolute;
      top: 0;
      right: 0;
      color: #6c757d; }

.custom-pagination .page-item .page-link {
  text-align: center;
  border: none;
  background: none;
  border-radius: 50% !important;
  width: 50px;
  height: 50px;
  padding: 0;
  line-height: 50px;
  margin-right: 10px;
  margin-bottom: 10px; }

.custom-pagination .page-item.active .page-link {
  background: #ffba5a;
  -webkit-box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.2); }

.line-height-1-2 {
  line-height: 1.2; }

/* Mouse Animation */
.mouse {
  width: 100px;
  display: inline-block;
  position: absolute;
  left: 50%;
  bottom: 140px;
  z-index: 1;
  -webkit-transform: translateX(-25%);
  -ms-transform: translateX(-25%);
  transform: translateX(-25%); }

.mouse-icon {
  width: 25px;
  height: 45px;
  border: 2px solid white;
  border-radius: 15px;
  cursor: pointer;
  position: relative;
  text-align: center; }

.mouse-wheel {
  height: 6px;
  margin: 2px auto 0;
  display: block;
  width: 3px;
  background-color: white;
  border-radius: 50%;
  -webkit-animation: 1.6s ease infinite wheel-up-down;
  -moz-animation: 1.6s ease infinite wheel-up-down;
  animation: 1.6s ease infinite wheel-up-down; }

@-webkit-keyframes wheel-up-down {
  0% {
    margin-top: 2px;
    opacity: 0; }
  30% {
    opacity: 1; }
  100% {
    margin-top: 20px;
    opacity: 0; } }

@-moz-keyframes wheel-up-down {
  0% {
    margin-top: 2px;
    opacity: 0; }
  30% {
    opacity: 1; }
  100% {
    margin-top: 20px;
    opacity: 0; } }

@keyframes wheel-up-down {
  0% {
    margin-top: 2px;
    opacity: 0; }
  30% {
    opacity: 1; }
  100% {
    margin-top: 20px;
    opacity: 0; } }

.site-block-half .image, .site-block-half .text {
  width: 100%; }
  @media (min-width: 992px) {
    .site-block-half .image, .site-block-half .text {
      width: 50%; } }

@media (max-width: 991.98px) {
  .site-block-half .image {
    margin-bottom: 50px;
    height: 300px; } }

.site-block-half .text {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px; }
  @media (min-width: 992px) {
    .site-block-half .text {
      padding: 4rem; } }

.site-block-half .bg-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }
  @media (max-width: 991.98px) {
    .site-block-half .bg-image {
      height: 400px; } }

.site-block-half.site-block-video .image {
  position: relative; }
  .site-block-half.site-block-video .image .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 20px;
    width: 70px;
    height: 70px;
    background: #fff;
    display: block;
    border-radius: 50%;
    opacity: 1;
    color: #ffba5a !important; }
    .site-block-half.site-block-video .image .play-button:hover {
      opacity: 1; }
    .site-block-half.site-block-video .image .play-button > span {
      position: absolute;
      left: 55%;
      top: 50%;
      -webkit-transform: translate(-60%, -50%);
      -ms-transform: translate(-60%, -50%);
      transform: translate(-60%, -50%); }

.field-icon-wrap {
  position: relative; }
  .field-icon-wrap .form-control {
    position: relative;
    padding-right: 40px;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    color: #a6a6a6; }
  .field-icon-wrap .icon {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 15px;
    z-index: 2; }
  .field-icon-wrap select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%; }

.block-32 {
  transform: translateY(-100%);
  background: #fff;
  padding: 30px;
  -webkit-box-shadow: 0 2px 80px -10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 80px -10px rgba(0, 0, 0, 0.2);
  width: 100%; }
  .block-32 .field-icon-wrap {
    position: relative; }
    .block-32 .field-icon-wrap .form-control {
      position: relative;
      padding-right: 40px;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
      color: #a6a6a6; }
    .block-32 .field-icon-wrap .icon {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      right: 15px;
      z-index: 2; }
    .block-32 .field-icon-wrap select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      width: 100%; }

.block-2 {
  margin-bottom: 50px;
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -ms-perspective: 1000;
  perspective: 1000;
  -ms-transform: perspective(1000px);
  -moz-transform: perspective(1000px);
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d; }
  .block-2:hover .back, .block-2.hover .back {
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg); }
  .block-2:hover .front, .block-2.hover .front {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg); }
  .block-2, .block-2 .front, .block-2 .back {
    width: 100%;
    height: 427px; }
  .block-2 .flipper {
    -webkit-transition: 0.6s;
    -webkit-transform-style: preserve-3d;
    -ms-transition: 0.6s;
    -moz-transition: 0.6s;
    -moz-transform: perspective(1000px);
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    -o-transition: 0.6s;
    transition: 0.6s;
    transform-style: preserve-3d;
    position: relative; }
  .block-2 .front, .block-2 .back {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 4px;
    -webkit-transition: 0.6s;
    -webkit-transform-style: preserve-3d;
    -webkit-transform: rotateY(0deg);
    -moz-transition: 0.6s;
    -moz-transform-style: preserve-3d;
    -moz-transform: rotateY(0deg);
    -o-transition: 0.6s;
    -o-transform-style: preserve-3d;
    -o-transform: rotateY(0deg);
    -ms-transition: 0.6s;
    -ms-transform-style: preserve-3d;
    -ms-transform: rotateY(0deg);
    transition: 0.6s;
    transform-style: preserve-3d;
    transform: rotateY(0deg);
    position: absolute;
    top: 0;
    left: 0; }
  .block-2 .front {
    -webkit-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    background: lightgreen;
    z-index: 2;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat; }
    .block-2 .front:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: -moz-linear-gradient(top, transparent 0%, transparent 18%, rgba(0, 0, 0, 0.8) 99%, rgba(0, 0, 0, 0.8) 100%);
      background: -webkit-linear-gradient(top, transparent 0%, transparent 18%, rgba(0, 0, 0, 0.8) 99%, rgba(0, 0, 0, 0.8) 100%);
      background: -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(18%, transparent), color-stop(99%, rgba(0, 0, 0, 0.8)), to(rgba(0, 0, 0, 0.8)));
      background: -o-linear-gradient(top, transparent 0%, transparent 18%, rgba(0, 0, 0, 0.8) 99%, rgba(0, 0, 0, 0.8) 100%);
      background: linear-gradient(to bottom, transparent 0%, transparent 18%, rgba(0, 0, 0, 0.8) 99%, rgba(0, 0, 0, 0.8) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#cc000000',GradientType=0 ); }
    .block-2 .front .box {
      position: absolute;
      bottom: 0;
      left: 20px;
      right: 20px;
      bottom: 20px; }
      .block-2 .front .box h2, .block-2 .front .box p {
        color: #fff;
        margin: 0;
        padding: 0;
        line-height: 1.5; }
      .block-2 .front .box h2 {
        font-size: 20px; }
      .block-2 .front .box p {
        font-size: 12px; }
  .block-2 .back {
    background: #fff;
    -webkit-box-shadow: 0 0 70px -10px rgba(0, 0, 0, 0.4);
    box-shadow: 0 0 70px -10px rgba(0, 0, 0, 0.4);
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    -o-transform: rotateY(-180deg);
    -ms-transform: rotateY(-180deg);
    transform: rotateY(-180deg); }
  .block-2 .back p {
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    text-align: center;
    padding: 0 20px;
    font-size: 18px; }
  .block-2 .author {
    bottom: 0;
    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 20px; }
    .block-2 .author .image {
      width: 40px; }
      .block-2 .author .image img {
        border-radius: 50%;
        max-width: 100%; }
    .block-2 .author .position {
      display: block;
      font-size: 12px; }


.text-black {
  color: #000 !important; }

.check-availabilty {
  margin-top: -170px;
  position: relative; 
}
.check-availabilty .block-32 {
  background: #fff;
  border-radius: 20px 20px 0px 0px; 
}

.room {
  position: relative;
  display: block; }
  .room .img-wrap {
    position: relative;
    overflow: hidden; }
    .room .img-wrap img {
      -webkit-transition: .3s all ease-in-out;
      -o-transition: .3s all ease-in-out;
      transition: .3s all ease-in-out;
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
      margin-bottom: 0 !important; }
  .room:hover .img-wrap img, .room:focus .img-wrap img {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05); }

.letter-spacing-1 {
  letter-spacing: .1em; }

.text-opacity-8 {
  opacity: .8; }

.text-opacity-7 {
  opacity: .7; }

.text-opacity-6 {
  opacity: .6; }

.text-opacity-5 {
  opacity: .5; }

.letter-spacing-2 {
  letter-spacing: .2em; }

.bg-image {
  background-size: cover;
  background-attachment: fixed; }

.bg-image-2 {
  background-size: cover;
  background-position: center center; }

.menu-desktop li a{
  text-decoration: none;
  padding: 0 1vw;
  transition: all .3s ease-in-out;
}
.site-header.scrolled .site-logo a:hover,
.menu-desktop li a:hover{
  color: #b98159;
}

.food-menu-tabs {
  text-align: center; }
  .food-menu-tabs .nav-tabs {
    text-align: center;
    display: inline-block;
    text-transform: uppercase;
    border-bottom: none; }
    .food-menu-tabs .nav-tabs li {
      display: inline-block; }
      .food-menu-tabs .nav-tabs li a {
        border: none;
        background: none;
        font-size: 1.2rem;
        font-weight: bold;
        position: relative;
        display: block; }
        .food-menu-tabs .nav-tabs li a:before {
          -webkit-transition: .3s all ease;
          -o-transition: .3s all ease;
          transition: .3s all ease;
          content: "";
          position: absolute;
          bottom: 0;
          left: 16px;
          right: 20px;
          height: 2px;
          background: transparent; }
        .food-menu-tabs .nav-tabs li a.active {
          border: none;
          background: none;
          color: #fff; }
          .food-menu-tabs .nav-tabs li a.active:before {
            background: #fff; }

.custom-caption {
  font-size: 14px;
  letter-spacing: .2em; }

.bg-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  z-index: 1; }
  .bg-image.overlay {
    position: relative; }
    .bg-image.overlay > .container {
      position: relative;
      z-index: 3; }
    .bg-image.overlay:before {
      z-index: 2;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      background: rgba(0, 0, 0, 0.6); }

.img-absolute {
  position: absolute;
  bottom: -70px;
  right: -55px;
  z-index: 2;
}
.img-absolute img {
    max-width: 250px;
    border-radius: 50%;
    border: 10px solid #f2f4fb; 
}
@media (max-width: 991.98px) {
  .img-absolute {
    right: 0px;
  }
  .img-absolute img {
        max-width: 160px;
  }
}

.custom-breadcrumbs {
  list-style: none;
  padding: 0;
  margin: 0; }
  .custom-breadcrumbs li {
    display: inline-block;
    color: #fff;
    margin: 0 4px;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: .1em;
    font-weight: bold; }
    .custom-breadcrumbs li a {
      font-weight: normal;
    }
    .custom-breadcrumbs li a:hover {
    color: #fff;
    }

.timeline-item {
  padding: 3em 2em 2em;
  position: relative;
  border-left: 2px solid #dee2e6; }
  .timeline-item::before {
    content: attr(date-is);
    position: absolute;
    left: 2em;
    font-weight: bold;
    top: 1em;
    display: block;
    font-weight: 700;
    font-size: .785rem; }
  .timeline-item::after {
    width: 10px;
    height: 10px;
    display: block;
    top: 1em;
    position: absolute;
    left: -6px;
    border-radius: 10px;
    content: '';
    border: 2px solid #dee2e6;
    background: white; }
  .timeline-item:last-child {
    -webkit-border-image: -webkit-gradient(linear, left top, left bottom, color-stop(60%, #dee2e6), to(rgba(222, 226, 230, 0))) 1 100%;
    -webkit-border-image: -webkit-linear-gradient(top, #dee2e6 60%, rgba(222, 226, 230, 0)) 1 100%;
    -o-border-image: -o-linear-gradient(top, #dee2e6 60%, rgba(222, 226, 230, 0)) 1 100%;
    border-image: -webkit-gradient(linear, left top, left bottom, color-stop(60%, #dee2e6), to(rgba(222, 226, 230, 0))) 1 100%;
    border-image: linear-gradient(to bottom, #dee2e6 60%, rgba(222, 226, 230, 0)) 1 100%; }

.custom-pagination {
  text-align: center; }
  .custom-pagination ul {
    padding: 0;
    margin: 0; }
    .custom-pagination ul li {
      display: inline-block; }
      .custom-pagination ul li a {
        background: #fff;
        border-radius: 50%;
        border: 1px solid transparent; }
        .custom-pagination ul li a:hover {
          border: 1px solid #d9d9d9;
          -webkit-box-shadow: 0 4px 10px -2px rgba(0, 0, 0, 0.1);
          box-shadow: 0 4px 10px -2px rgba(0, 0, 0, 0.1); }
      .custom-pagination ul li a, .custom-pagination ul li span {
        display: inline-block;
        width: 47px;
        margin: 2px;
        height: 47px;
        font-size: 1.2rem;
        line-height: 47px; }
      .custom-pagination ul li.active span {
        background: #ffba5a;
        color: #fff;
        border-radius: 50%; }


.mask-hero {
  position: absolute;
  height: 100%;
  width: 100%;
  top: -20px;
  left: 0;
  z-index: 0;
}
.z-index-1 {
  z-index: 1
}
.p-extra {
  padding: 55px;
}
.hero-bg-light {
  background-color: #fef9f6;
}
.hero-bg-light2 {
  background-color: #f5e2d4;
}
.hero-bg-dark {
  background-color: #e2ab81;
}


@media (max-width: 991.98px) {
  .block-2 .back {
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg); }
  .block-2 .front {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
  }
}
.check-availabilty {
  height: 0;
}
@media (max-width: 768px) {
  .check-availabilty {
    height: auto;
  }
  .check-availabilty .block-32 {
    transform: translateY(0);
    border-radius: 20px 20px 20px 20px
  }
}

.btn-parent .btn-selce {
  background-color: #3d1a0f;
  border-radius: 1px;
  padding: 8px 23px;
}

.btn-parent .btn-selce:hover {
  background-color: #876a5e;
  border-radius: 1px;
}

.btn-menu-selce {
  padding: 7px 30px 9px;
  border-radius: 12px;
  border: 0;
  color: #fff;
  font-family: "Prata", arial, sans-serif;
  background-image: linear-gradient(to right, #dcbea7, #dbae8c, #bf987b, #bd845c, #b17850);
  box-shadow: 0 3px 3px 0 rgba(179, 131, 98, 0.75);
  font-weight: 600;
  cursor: pointer;
  text-align:center;
  border: none;
  background-size: 300% 100%;

  moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}
.btn-menu-selce:hover {
  background-position: 100% 0;
  moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}
.btn-menu-selce:focus {
  outline: none;
}

.o-0 {
  opacity: 0;
}

section {
  overflow-x: hidden;
}
